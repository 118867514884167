/* eslint-disable camelcase */
import React, {Component} from 'react';
import {AmigosContainer, CustomCheckbox, ImageContainer, ImageContentContainer, InviteContainer, Layout, LocationContainer, RemovePhotoButton, TitleContainer} from "../CreateEvent/styles";
import {checkImage, getOrientation, resetOrientation, checkMultipleImage} from "../../utils/methods";
import {toast} from "react-toastify";
import {ImageUrl, imageErrorMessage} from "../../utils/constants";
import PropTypes from "prop-types";
import { createCompany } from "../../redux/actions";
import {connect} from "react-redux";
import moment from 'moment';
import RadioButton from "../CustomRadioButton";
import {ShowIngredientContent, AddButton, CustomInputIngredients, NameContainer, InviteAmigo, FieldTitleIngredients, IngredientButtonContainer, InputContainer, StyledDateTime, DateIconContainer, PageTitle, FieldTitle, StyledInput, ImageInput, SaveButton,
  TopContainer, StyledPassword, CheckMark, ClientAnniversary, ScrollableContainer, FormContainer} from './styles';
import LazyImage from '../common/LazyImage/LazyImage';

let fileArray = [];
class CompanyFeature extends Component{
  constructor(props) {
    super(props);
    this.state = {
      display: false,
      title: '',
      headerImgSrc: '',
      footerImgSrc: '',
      headerImageName: '',
      footerImageName: '',
      Blurb: '',
      recipeCategorieslist: [],
      userCategorieslist: [],
      addOnlist: [],
      showContent:'',
      locationContent: '',
      departmentContent: '',
      LocationList: [],
      DepartmentList:[],
      directions:[],
      imageFiles:[],
      editedContent: [],
      editSteps:[],
      description:'',
      error: [],
      isLocationDisable: false,
      isDepartmentDisable: false,
      isAddonSelected: false,
      startRewardDate: undefined,
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      endRewardDate: undefined,
      employeeCount: undefined,
      clientAnniversaryDate: undefined,
      password: '',
      show: false,
      validDate: undefined,
      isDisabledButton: false
    };
  }

  componentDidMount(){
    const { recipeCategorieslist, userCategorieslist } = this.state;
    recipeCategorieslist.push(11);
    recipeCategorieslist.push(12);
    recipeCategorieslist.push(13);
    recipeCategorieslist.push(14);
    userCategorieslist.push(3);
    let date = new Date(), y = date.getFullYear(), m = date.getMonth();
    let firstDay;
    if(m===11){
      firstDay = new Date(y+1, 0, 1);
    }else{
      firstDay = new Date(y, m+1, 1);
    }
    firstDay = moment(firstDay).format('MMMM Do,YYYY');
    this.setState({
      clientAnniversaryDate: firstDay,
      recipeCategorieslist: recipeCategorieslist
    })
  }

  onChangeInput = (e) => {
    if(e.target.value !== ' ') {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  };

  showDatePicker = (value) => {
    if(this.state.openDateTimePicker === value) {
      this.setState({
        openDateTimePicker: ''
      });
    } else {
      this.setState({
        openDateTimePicker: value
      });
    }
  };

  changeDate = (e, current) => {
    if(current === 'date'){
      this.setState({
        startRewardDate : moment(e._d).format('MMMM Do,YYYY'),
        openDateTimePicker: '',
        startDate: moment(e._d).format('YYYY-MM-DD')
      })
    }
    else if(current === 'enddate'){
      let date = e._d
      let firstDay, m=date.getMonth(), y = date.getFullYear();
      if(m===11){
        firstDay = new Date(y+1, 0, 1)
      }
      else{
        firstDay = new Date(y, m+1, 1)
      }
      firstDay = moment(firstDay).format('MMMM Do,YYYY');
      this.setState({
        endRewardDate : moment(e._d).format('MMMM Do,YYYY'),
        openDateTimePicker: '',
        endDate: moment(e._d).format('YYYY-MM-DD'),
        clientAnniversaryDate: firstDay
      })
    }
  };

  onChange = (e) => {
    let file = e.target.files[0];
    let fileArr = e.target.files;
    if (e.target.files[0]) {
      if (checkImage(fileArr)) {
        if ((file?.size / 1000000) <= 20) {
          let reader = new FileReader();
          reader.readAsDataURL(file);
          const array = document.getElementById('recipe-upload-file').value.split("\\");
          reader.onloadend = () => {
            this.setState({
              headerImgSrc: reader.result,
              headerImageName: array[array.length - 1],
            });
            getOrientation(file, (or) => {
              resetOrientation([reader.result], or, (baseImage) => {
                this.setState({
                  headerImgSrc: baseImage
                });
              });
            });
          };
        } else {
          toast.error('Please select image file less than 20MB');
          document.getElementById('recipe-upload-file').value = '';
        }
      } else {
        toast.error(imageErrorMessage);
        document.getElementById('recipe-upload-file').value = '';
      }
    }
  };
  removePhoto = () => {
    document.getElementById('recipe-upload-file').value = '';
    this.setState({
      headerImgSrc: '',
      headerImageName: '',
    });
  };

  onChangeFooter = (e) => {
    let file = e.target.files[0];
    let fileArr = e.target.files;
    if (e.target.files[0]) {
      if (checkImage(fileArr)) {
        if ((file?.size / 1000000) <= 20) {
          let reader = new FileReader();
          reader.readAsDataURL(file);
          const array = document.getElementById('footer-upload-file').value.split("\\");
          reader.onloadend = () => {
            this.setState({
              footerImgSrc: reader.result,
              footerImageName: array[array.length - 1],
            });
            getOrientation(file, (or) => {
              resetOrientation([reader.result], or, (baseImage) => {
                this.setState({
                  footerImgSrc: baseImage
                });
              });
            });
          };
        } else {
          toast.error('Please select image file less than 20MB');
          document.getElementById('footer-upload-file').value = '';
        }
      } else {
        toast.error(imageErrorMessage);
        document.getElementById('footer-upload-file').value = '';
      }
    }
  };
  removeFooterPhoto = () => {
    document.getElementById('footer-upload-file').value = '';
    this.setState({
      footerImgSrc: '',
      footerImageName: '',
    });
  };

  onSelectTypes = (name, value) => {
    this.setState({
      [name]: value,
    });
  };
  onChangeCategory = (id) => {
    const { recipeCategorieslist } = this.state;
    if(recipeCategorieslist.includes(id) && id!==11 && id!==12 && id!==13 && id!==14 && id!==15) {
      let index = recipeCategorieslist.findIndex((item) => item === id);
      if(index > -1) {
        recipeCategorieslist.splice(index, 1);
      }
    } else {
      recipeCategorieslist.push(id);
    }
    this.setState({
      recipeCategorieslist: recipeCategorieslist
    })
  };

  onChangeUserCategory = (id) => {
    const { userCategorieslist } = this.state;
    if(userCategorieslist.includes(id) && id!==3) {
      let index = userCategorieslist.findIndex((item) => item === id);
      if(index > -1) {
        userCategorieslist.splice(index, 1);
      }
    } else {
      userCategorieslist.push(id);
    }
    this.setState({
      userCategorieslist: userCategorieslist
    })
  };

  onChangeAddCategory = (id) => {
    const { addOnlist } = this.state;
    if(addOnlist.includes(id)) {
      let index = addOnlist.findIndex((item) => item === id);
      if(index > -1) {
        addOnlist.splice(index, 1);
        if(addOnlist.length === 1){
          this.setState({
            isAddonSelected : false
          })
        }
      }
    } else {
      addOnlist.push(id);
    }
    this.setState({
      addOnlist: addOnlist
    })
  };

  selectAllCategories = (e, recipeCategories) => {
    e.preventDefault();
    const { recipeCategorieslist } = this.state;
    if(recipeCategorieslist.length === recipeCategories.length) {
      this.setState({
        recipeCategorieslist: []
      })
    }
    else {
      let arr = [];
      recipeCategories && recipeCategories.map((data) => {
        arr.push(data.id);
      });
      this.setState({
        recipeCategorieslist: arr
      })
    }
  };

  onChangeDepartment = (e) => {
    if(e.target.value !== ' ') {
      this.setState({departmentContent: e.target.value})
    }
  };
  onChangeLocation = (e) => {
    if(e.target.value !== ' ') {
      this.setState({locationContent: e.target.value})
    }
  };
  storeLocation = () => {
    const{locationContent, LocationList} = this.state;
    let arr = [];
    arr = [...LocationList];
    arr.push(locationContent);
    this.setState({LocationList: arr, locationContent: ''});
  };
  removeLocation = (index) => {
    const{LocationList} = this.state;
    LocationList.splice(index, 1);
    this.setState({LocationList: LocationList});
  };

  storeDepartment = () => {
    const{departmentContent, DepartmentList} = this.state;
    let arr = [];
    arr = [...DepartmentList];
    arr.push(departmentContent);
    this.setState({DepartmentList: arr, departmentContent: ''});
  };
  removeDepartment = (index) => {
    const{DepartmentList} = this.state;
    DepartmentList.splice(index, 1);
    this.setState({DepartmentList: DepartmentList});
  };

  onChangeMultiple = (e, click) => {
    const{imageFiles} = this.state;
    let fileArr = [...e.target.files];
    if ((this.state.imageFiles.length + fileArr.length) < 7 && fileArr.length < 7) {
      fileArr.map((imageFile) => {
        if (checkMultipleImage(imageFile)) {
          if ((imageFile?.size / 1000000) <= 20) {
            let reader = new FileReader();
            reader.readAsDataURL(imageFile);
            const fileName = imageFile.name;
            let obj = {imageName: '', imgSrc: ''};
            reader.onloadend = () => {
              getOrientation(imageFile, (or) => {
                resetOrientation([reader.result], or, (baseImage) => {
                  let existIndex = imageFiles.findIndex((data) => data['imageName'] == fileName);
                  if(existIndex == -1) {
                    obj['imageName'] = fileName;
                    obj['imgSrc'] = baseImage;
                    fileArray.push(obj);
                    let dubImageFiles = [...imageFiles, ...fileArray];
                    this.setState({imageFiles: dubImageFiles});
                  }
                });
              });
            };
          } else {
            toast.error('Please select image file less than 20MB');
            document.getElementById('multiple-recipe-upload-file').value = '';
          }
        } else {
          toast.error(imageErrorMessage);
          document.getElementById('multiple-recipe-upload-file').value = '';
        }
      });
      fileArray = [];
    } else {
      if(click) {
        toast.error('Please select maximum of 6 images');
      }
    }
  };

  onChangeCount = (e) => {
    this.setState({
      employeeCount: e.target.value
    })
  }

  selectMany = (id) => {
    const { recipeCategorieslist } = this.state;
    if(id===1){
      recipeCategorieslist.push(19);
      recipeCategorieslist.push(15);
      recipeCategorieslist.push(20);
      // recipeCategorieslist.push(18);
      recipeCategorieslist.push(17);
  
    
      if(recipeCategorieslist.includes(16)){
        let index = recipeCategorieslist.findIndex((item) => item === 16);
        if(index > -1) {
          recipeCategorieslist.splice(index, 1);
        }
      }
      // if(recipeCategorieslist.includes(17)){
      //   let index = recipeCategorieslist.findIndex((item) => item === 17);
      //   if(index > -1) {
      //     recipeCategorieslist.splice(index, 1);
      //   }
      // }
      if(recipeCategorieslist.includes(18)){
        let index = recipeCategorieslist.findIndex((item) => item === 17);
        if(index > -1) {
          recipeCategorieslist.splice(index, 1);
        }
      }
      if(recipeCategorieslist.includes(21)){
        let index = recipeCategorieslist.findIndex((item) => item === 21);
        if(index > -1) {
          recipeCategorieslist.splice(index, 1);
        }
      }
      if(recipeCategorieslist.includes(22)){
        let index = recipeCategorieslist.findIndex((item) => item === 22);
        if(index > -1) {
          recipeCategorieslist.splice(index, 1);
        }
      }
      if(recipeCategorieslist.includes(23)){
        let index = recipeCategorieslist.findIndex((item) => item === 23);
        if(index > -1) {
          recipeCategorieslist.splice(index, 1);
        }
      }
    }else{
      recipeCategorieslist.push(16);
      recipeCategorieslist.push(17);
      recipeCategorieslist.push(21);
      recipeCategorieslist.push(22);
      recipeCategorieslist.push(23);
      recipeCategorieslist.push(19);
      recipeCategorieslist.push(15);
      recipeCategorieslist.push(20);
      recipeCategorieslist.push(18);

    }
    
    this.setState({
      recipeCategorieslist: recipeCategorieslist
    })
  }

  disableDepartment = () => {
    const { DepartmentList } = this.state;
    DepartmentList.length = 0;
    this.setState((prev)=>({
      isDepartmentDisable: !prev.isDepartmentDisable,
      DepartmentList: DepartmentList
    }))
  }

  disableLocation = () => {
    const { LocationList } = this.state;
    LocationList.length = 0;
    this.setState((prev)=>({
      isLocationDisable: !prev.isLocationDisable,
      LocationList: LocationList
    }))
  }

  selectAllAddons = () => {
    const { isAddonSelected, addOnlist } = this.state;
    if( isAddonSelected===false ){
      addOnlist.push(1);
      addOnlist.push(2);
      addOnlist.push(3);
      addOnlist.push(4);
      addOnlist.push(5);
      this.setState({
        addOnlist: addOnlist,
        isAddonSelected: true
      })
    }
    else{
      this.setState({
        addOnlist: [],
        isAddonSelected: false
      })
    }
  }

  submitCompanyFeature = () => {
    const { password, recipeCategorieslist, userCategorieslist, addOnlist, title, headerImgSrc, employeeCount, startDate, endDate, LocationList, DepartmentList} = this.state;
    const { createCompanyFeature } = this.props;
    this.setState({
      isDisabledButton : true
    })
    let obj = {
      company_name: title,
      company_password: password,
      company_header_logo: headerImgSrc,
      eligible_employee_count: employeeCount,
      reward_start_date: startDate,
      reward_end_date: endDate,
      company_locations : LocationList,
      company_departments: DepartmentList,
      main_features:{
        user_dashboard: recipeCategorieslist.includes(14) ? 1 : 0,
        challenges: recipeCategorieslist.includes(12) ? 1 : 0,
        social_feed: recipeCategorieslist.includes(11) ? 1 : 0,
        education: recipeCategorieslist.includes(13) ? 1 : 0,
        rewards: recipeCategorieslist.includes(15) ? 1 : 0,
        events: recipeCategorieslist.includes(16) ? 1 : 0,
        employee_directory: recipeCategorieslist.includes(17) ? 1 : 0,
        leaderboard: recipeCategorieslist.includes(18) ? 1 : 0,
        profile_page: recipeCategorieslist.includes(19) ? 1 : 0,
        log_workout: recipeCategorieslist.includes(20) ? 1 : 0,
        content_creator: recipeCategorieslist.includes(21) ? 1 : 0,
        monthly_tasks: recipeCategorieslist.includes(22) ? 1 : 0,
        challenge_live_status: recipeCategorieslist.includes(23) ? 1 : 0

      },
      roles:{
        spouse: userCategorieslist.includes(1) ? 1 : 0,
        dependent: userCategorieslist.includes(2) ? 1 : 0
      },
      addon_features:{
        recognition: addOnlist.includes(1) ? 1 : 0,
        surveys: addOnlist.includes(2) ? 1 : 0,
        company_rewards: addOnlist.includes(3) ? 1 : 0,
        show_biometric: addOnlist.includes(4) ? 1 : 0
      }
    }
    createCompanyFeature(obj);
  }

  togglePassword = () => {
    const password = document.querySelector('#password');
    const type = password.getAttribute('type') === 'password' ? 'text' : 'password';
    password.setAttribute('type', type);
    this.setState((prev)=>({
      display: !prev.display
    }))
  }

  handlePassword = (e) => {
    if(e.target.value !== ' '){
      this.setState({
        password: e.target.value
      })
    }
    if(e.target.value.length<8){
      this.setState({
        show: true
      })
    }else{
      this.setState({
        show: false
      })
    }
  }

  hideModal = (event) => {
    const { openDateTimePicker } = this.state;
    let ignoreClickOnMeElement = document.getElementsByClassName('calendar');
    let isClickInsideElement = ignoreClickOnMeElement[0].contains(event.target);
    if (!isClickInsideElement) {
      //Do something click is outside specified element
      if( openDateTimePicker==="date" || openDateTimePicker==="enddate" ){
        this.setState({
          openDateTimePicker: ''
        })
      }
    }
  }

  render() {
    const{show, password, display, title, locationContent, departmentContent, showDateTimeInput, recipeCategorieslist, DepartmentList,
      LocationList, openDateTimePicker, userCategorieslist, addOnlist, isLocationDisable, isDepartmentDisable, isAddonSelected,
      startRewardDate, endRewardDate, clientAnniversaryDate, employeeCount, headerImageName, isDisabledButton, headerImgSrc} = this.state;
    let isDisabled = false;
    if(title === '' || recipeCategorieslist.length <= 0 || employeeCount===undefined || userCategorieslist<=0 || password === '' || password.length<8){
      isDisabled = true;
    }

    const addOnList = [
      {
        "id": 1,
        "add_tag": "Recognition",
        "sendId": "recognition"
      },
      {
        "id": 2,
        "add_tag": "Engagement Surveys",
        "sendId": "surveys"
      },
      {
        "id": 3,
        "add_tag": "Company Rewards",
        "sendId": "company_rewards"
      },
      // {
      //   "id": 4,
      //   "add_tag": "Biometrics",
      //   "sendId": "show_biometric"
      // }
    ]

    const userList = [
      {
        "id": 3,
        "user_tag": "Employee",
        "sendId": "employee"

      },
      {
        "id": 1,
        "user_tag": "Spouse",
        "sendId": "spouse"
      },
      {
        "id": 2,
        "user_tag": "Dependent",
        "sendId": "dependent"

      }
    ]

    const featureList = [
      {
        "id": 14,
        "recipe_tag": "User Dashboard",
        "sendId": "user_dashboard"
      },
      {
        "id": 12,
        "recipe_tag": "Challenges",
        "sendId": "challenges"
      },
      {
        "id": 11,
        "recipe_tag": "Social Feed",
        "sendId": "social_feed"
      },
      {
        "id": 13,
        "recipe_tag": "Education",
        "sendId": "education"
      },
      {
        "id": 15,
        "recipe_tag": "User Rewards",
        "sendId": "rewards"
      },
      {
        "id": 18,
        "recipe_tag": "Leaderboard",
        "sendId": "leaderboard"
      },
      {
        "id": 19,
        "recipe_tag": "User Profile Page",
        "sendId": "profile_page"
      },
      {
        "id": 20,
        "recipe_tag": "Log Workout",
        "sendId": "log_workout"
      },
      {
        "id": 17,
        "recipe_tag": "Employee Directory",
        "sendId": "employee_directory"
      },
      {
        "id": 16,
        "recipe_tag": "Events",
        "sendId": "events"
      },
      {
        "id": 22,
        "recipe_tag": "Monthly Tasks",
        "sendId":"monthly_tasks"
      },
      {
        "id": 21,
        "recipe_tag":"Content Creator",
        "sendId": "content_creator"
      },
      {
        "id": 23,
        "recipe_tag": "Personal Challenge",
        "sendId": "content_creator"
      }
    ]
    const valid = (currentDate) => showDateTimeInput === 'date' ? currentDate.isAfter(moment().subtract(1, 'days')) :
      currentDate.isAfter(moment().subtract(1, 'days'));
    return (
      <Layout className="hideDiv" onClick={this.hideModal}>
        <PageTitle>Create A New Company</PageTitle>
        <div style={{float: 'left', width: '100%'}}>
          <FormContainer>
            <TitleContainer>
              <FieldTitle>Company Name</FieldTitle>
              <StyledInput
                placeholder="Type Company Name here..."
                name="title"
                onChange={this.onChangeInput}
                value={title}
                maxLength="100"
                type="text"
              />
            </TitleContainer>
            <ImageContainer>
              <FieldTitle>Add Company Header Logo</FieldTitle>
              <div>
                { headerImageName &&
              <div>
                <img src={headerImgSrc} />
                <ImageContentContainer>
                  <div>{headerImageName}</div>
                  <RemovePhotoButton color="#aaaaaa" type="button" onClick={this.removePhoto}>Remove photo</RemovePhotoButton>
                </ImageContentContainer>
              </div>
                }
                <ImageInput htmlFor="recipe-upload-file" showAtBottom={headerImageName !== ''}>
                  {headerImageName ? 'browse new' : 'browse'}
                  <input
                    id="recipe-upload-file"
                    type="file"
                    name="recipeImage"
                    accept=".jpeg, .png, .jpg"
                    multiple={false}
                    onChange={(e) => this.onChange(e)}
                    onClick={(e) => e.target.files[0] && this.onChange(e)}
                  />
                </ImageInput>
              </div>
            </ImageContainer>
            <TitleContainer>
              <FieldTitle>Eligible Users</FieldTitle>
              <StyledInput
                placeholder="Enter the number of eligible users here"
                name="Blurb"
                type="number"
                min="0"
                onChange={this.onChangeCount}
              />
            </TitleContainer>
            <div className="calendar">
              <TitleContainer>
                <FieldTitle>Points & Rewards Start Date</FieldTitle>
                <InputContainer widthInput={1}>
                  <StyledDateTime
                    open={openDateTimePicker === 'date'}
                    inputProps={{placeholder: moment().format('MMMM Do,YYYY'), readOnly: true}}
                    dateFormat="MM/DD/YYYY"
                    closeOnSelect={true}
                    closeOnTab={true}
                    closeOnClickOutside={true}
                    timeFormat={false}
                    value={startRewardDate}
                    onChange={(e) => this.changeDate(e, 'date')}
                    isValidDate={valid} 
                  />
                  <DateIconContainer  onClick={() => this.showDatePicker('date')}>
                    <LazyImage src={ImageUrl + "/images/calender.png"} alt="calender"/>
                  </DateIconContainer>
                </InputContainer>
              </TitleContainer>

              <TitleContainer>
                <FieldTitle>Points & Rewards End Date</FieldTitle>
                <InputContainer widthInput={1}>
                  <StyledDateTime
                    open={openDateTimePicker === 'enddate'}
                    inputProps={{placeholder: moment().format('MMMM Do,YYYY'), readOnly: true}}
                    dateFormat="MM/DD/YYYY"
                    closeOnSelect={true}
                    closeOnTab={true}
                    closeOnClickOutside	= {true}
                    timeFormat={false}
                    value={endRewardDate}
                    onChange={(e) => this.changeDate(e, 'enddate')}
                    isValidDate={valid}
                  />
                  <DateIconContainer  onClick={() => this.showDatePicker('enddate')}>
                    <LazyImage src={ImageUrl + "/images/calender.png"} alt="calender"/>
                  </DateIconContainer>
                </InputContainer>
              </TitleContainer>
            </div>
            <ClientAnniversary>
              <div>Client Anniversary</div>
              <div>{clientAnniversaryDate}</div>
              <div>This is the day the client points and rewards will rest on the platform for all users.</div>
            </ClientAnniversary>
            <TitleContainer>
              <div className="outerDiv">
                <div>
                  <FieldTitleIngredients>Locations:</FieldTitleIngredients>
                </div>
                <div>
                  <div>
                    <CustomCheckbox>
                      <input
                        type="checkbox"
                        checked={isLocationDisable}
                        onChange={() => this.disableLocation()}
                      />
                      <CheckMark checked={isLocationDisable} />
                    </CustomCheckbox>
                  </div>
                  <NameContainer onClick={() => this.disableLocation()}>
                    <span>No Locations</span>
                  </NameContainer>
                </div>
              </div>
              <CustomInputIngredients
                placeholder="Enter the locations here"
                name="LocationList"
                onChange={this.onChangeLocation }
                value={locationContent}
                type="text"
                disabled={isLocationDisable}
              />
              { locationContent.replace(/^\s\s*/, '').replace(/\s\s*$/, '') && <IngredientButtonContainer><AddButton onClick={this.storeLocation}>{"Add Location"}</AddButton></IngredientButtonContainer>}
              {LocationList.map((data,index) => (
                <ShowIngredientContent key={index}>
                  <div>
                    <div>{data}</div>
                    <i className="fa fa-times" aria-hidden="true" onClick={() => this.removeLocation(index)}/>
                  </div>
                </ShowIngredientContent>
              ))}
            </TitleContainer>
            <TitleContainer>
              <div className="outerDiv">
                <div>
                  <FieldTitleIngredients>Department:</FieldTitleIngredients>
                </div>
                <div>
                  <div>
                    <CustomCheckbox>
                      <input
                        type="checkbox"
                        checked={isDepartmentDisable}
                        onChange={() => this.disableDepartment()}
                      />
                      <CheckMark checked={isDepartmentDisable} />
                    </CustomCheckbox>
                  </div>
                  <NameContainer onClick={() => this.disableDepartment()}>
                    <span>No Departments</span>
                  </NameContainer>
                </div>
              </div>
              <CustomInputIngredients
                placeholder="Type Department here..."
                name="Department"
                onChange={this.onChangeDepartment }
                value={departmentContent}
                type="text"
                disabled={isDepartmentDisable}
              />
              { departmentContent.replace(/^\s\s*/, '').replace(/\s\s*$/, '') && <IngredientButtonContainer><AddButton onClick={this.storeDepartment}>{"Add Department"}</AddButton></IngredientButtonContainer>}
              {DepartmentList.map((data,index) => (
                <ShowIngredientContent key={index}>
                  <div>
                    <div>{data}</div>
                    <i className="fa fa-times" aria-hidden="true" onClick={() => this.removeDepartment(index)}/>
                  </div>
                </ShowIngredientContent>
              ))}
            </TitleContainer>
            <AmigosContainer>
              <TopContainer>
                <FieldTitle className="main">Main Platform Features<br/><span>Please select the platform features you like to turn on</span></FieldTitle>
                {featureList && featureList.length > 0 &&
                  <span className="feature">
                    <RadioButton id="1" name = "gender" handler={this.selectMany} value={'Essential Features'} label={'Core Plan'} companyFeature={1}/>
                    <RadioButton id="2" name = "gender" handler={this.selectMany} value ={'Core Features'} label={'Premium Plan'} companyFeature={1}/>
                    <RadioButton id="3" name = "gender" handler={this.selectMany} value ={'Core Features'} label={'Enterprise Plan'} companyFeature={1}/>
                  </span>
                }
              </TopContainer>
              <InviteContainer>
                <ScrollableContainer>
                  <LocationContainer minHeight={featureList && featureList.length}>
                    {
                      featureList && featureList.length > 0 ? featureList.map((tag, index) => (

                        <InviteAmigo key={index}>
                          <div className="checkboxDiv">
                            <CustomCheckbox>
                              <input
                                type="checkbox"
                                checked={recipeCategorieslist.includes(tag['id'])}
                                onChange={() => this.onChangeCategory(tag['id'])}
                              />
                              <CheckMark checked={recipeCategorieslist.includes(tag['id'])} />
                            </CustomCheckbox>
                          </div>
                          <NameContainer onClick={() => this.onChangeCategory(tag['id'])}>
                            <span>{tag['recipe_tag']}</span>
                          </NameContainer>
                        </InviteAmigo>

                      )) : null
                    }
                  </LocationContainer>
                </ScrollableContainer>
              </InviteContainer>
            </AmigosContainer>
            <AmigosContainer>
              <TopContainer>
                <FieldTitle className="main">User and Roles<br/><span>Please select the users you like to have on the platform</span></FieldTitle>
              </TopContainer>
              <InviteContainer>
                <ScrollableContainer>
                  <LocationContainer minHeight={userList && userList.length}>
                    {
                      userList && userList.length > 0 ? userList.map((tag, index) => (

                        <InviteAmigo key={index}>
                          <div className="checkboxDiv">
                            <CustomCheckbox>
                              <input
                                type="checkbox"
                                checked={userCategorieslist.includes(tag['id'])}
                                onChange={() => this.onChangeUserCategory(tag['id'])}
                              />
                              <CheckMark checked={userCategorieslist.includes(tag['id'])} />
                            </CustomCheckbox>
                          </div>
                          <NameContainer onClick={() => this.onChangeUserCategory(tag['id'])}>
                            <span>{tag['user_tag']}</span>
                          </NameContainer>
                        </InviteAmigo>

                      )) : null
                    }
                  </LocationContainer>
                </ScrollableContainer>
              </InviteContainer>
            </AmigosContainer>
            <AmigosContainer>
              <TitleContainer>
                <div className="outerDiv">
                  <div>
                    <FieldTitle className="main">Add-On Features<br/><span>Please select the add-on you like to turn on</span></FieldTitle>
                  </div>
                  <div>
                    <div className="padding">
                      <CustomCheckbox>
                        <input
                          type="checkbox"
                          checked={isAddonSelected}
                          onChange={() => this.selectAllAddons()}
                        />
                        <CheckMark checked={isAddonSelected} />
                      </CustomCheckbox>
                    </div>
                    <NameContainer onClick={() => this.selectAllAddons()}>
                      <span>Company Bundle</span>
                    </NameContainer>
                  </div>
                </div>
              </TitleContainer>
              <InviteContainer>
                <ScrollableContainer>
                  <LocationContainer minHeight={addOnList && addOnList.length}>
                    {
                      addOnList && addOnList.length > 0 ? addOnList.map((tag, index) => (

                        <InviteAmigo key={index}>
                          <div className="checkboxDiv">
                            <CustomCheckbox>
                              <input
                                type="checkbox"
                                checked={addOnlist.includes(tag['id'])}
                                onChange={() => this.onChangeAddCategory(tag['id'])}
                              />
                              <CheckMark checked={addOnlist.includes(tag['id'])} />
                            </CustomCheckbox>
                          </div>
                          <NameContainer onClick={() => this.onChangeAddCategory(tag['id'])}>
                            <span>{tag['add_tag']}</span>
                          </NameContainer>
                        </InviteAmigo>

                      )) : null
                    }
                  </LocationContainer>
                </ScrollableContainer>
              </InviteContainer>
            </AmigosContainer>
            <TitleContainer>
              <FieldTitle>Company Password</FieldTitle>
              <StyledPassword>
                <input type="password" onChange={this.handlePassword} name="password" id="password" placeholder="Enter Company Password here..." />
                {
                  display ? <i className="far fa-eye-slash eye-icon" onClick={this.togglePassword} /> : <i className={`fas fa-eye eye-icon`} onClick={this.togglePassword} />
                }
                {
                  show ? <div className="pass">*Minimum 8 characters Required</div> : null
                }
              </StyledPassword>
            </TitleContainer>
            <ImageContainer>
              <SaveButton disabled={isDisabled || isDisabledButton} onClick={this.submitCompanyFeature} addMargin>Create Company</SaveButton>
            </ImageContainer>
          </FormContainer>
        </div>
      </Layout>
    );
  }
}
CompanyFeature.propTypes = {
  history: PropTypes.object,
  createCompanyFeature: PropTypes.func
};
const mapStateToProps = (state) => ({
  recipeLoading: state.education.recipeLoading
});
const mapDispatchToProps = (dispatch) => ({
  createCompanyFeature: (data) => dispatch(createCompany(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(CompanyFeature);
